<template>

    <div>

        <div class="breadcrumb justify-content-end">
            <div class="d-flex  breadcrumb-wrapper pr-1">
                <b-breadcrumb-item :to="{ name: 'dashboard' }"> <feather-icon    class="align-text-top" icon="HomeIcon" size="15"/></b-breadcrumb-item>
                <b-breadcrumb-item :to="{ name: MODULE_PREFIX+'s' }">{{$t('label_recommended')}}</b-breadcrumb-item>
                <b-breadcrumb-item active>{{$t(curPageLabel)}}</b-breadcrumb-item>
            </div>
        </div>

        <!--actions panel-->
        <b-card no-body v-if="$can('edit', MODULE_PREFIX+'.tab_details')">
            <b-card-body>
                <div class="text-uppercase mr-1 mb-1"><strong>{{moduleItem.name }}</strong>
                </div>
                <b-button variant="outline-primary"  class="mr-1" :to="{name:MODULE_PREFIX + 's_edit', params:{id:moduleItem.id}}">
                    <feather-icon
                            icon="EditIcon"
                            class="mr-50"
                    />
                    <span>{{$t('label_edit')}}</span>
                </b-button>


            </b-card-body>
        </b-card>

        <b-card no-body>

            <b-card-body>
                <b-tabs content-class="mt-1" >
                    <b-tab
                            class="pt-2"
                            v-if="$can('view', MODULE_PREFIX+'.tab_details')"
                           @click="curPageLabel = $t('label_informations')"
                            :title="$t('label_informations')"
                            lazy
                    >

                        <tab-details @provision-added="fetchModuleItem()" :edited-item="moduleItem" ></tab-details>

                    </b-tab>
                    <b-tab
                            class=""
                            v-if="$can('view', MODULE_PREFIX+'.documents')"
                            @click="curPageLabel = $t('label_documents')"
                            :title="$t('label_documents')"
                            lazy
                    >

                        <tab-documents :module-item="moduleItem" ></tab-documents>

                    </b-tab>

                    <b-tab
                            v-if="$can('view', MODULE_PREFIX+'.notes')"
                            @click="curPageLabel = $t('label_notes')"
                            :title="$t('label_notes')"
                            lazy
                    >
                        <tab-notes
                                @edit-item="editedNote = $event"
                                :ref="NOTE_PREFIX + '_CONTENT_TAB'"
                                :module-item="moduleItem"

                        ></tab-notes>

                    </b-tab>

                    <b-tab
                            v-if="$can('view', MODULE_PREFIX+'.payment')"
                            @click="curPageLabel = $t('label_payments')"
                            :title="$t('label_payments')"
                            lazy
                    >
                        <tab-payments

                                :ref="PAYMENT_PREFIX + '_CONTENT_TAB'"
                                :module-item="moduleItem"

                        ></tab-payments>

                    </b-tab>

                    <b-tab

                            v-if="$can('view', MODULE_PREFIX+'.mlmstructure')"
                            @click="curPageLabel = $t('label_mlm_structure')"
                            :title="$t('label_mlm_structure')"
                            lazy
                    >

                        <tab-mlm :module-item="moduleItem" ></tab-mlm>

                    </b-tab>

                    <b-tab

                            v-if="$can('view', MODULE_PREFIX+'.history')"
                            @click="curPageLabel = $t('label_history')"
                            :title="$t('label_history')"
                            lazy
                    >

                        <tab-history :module-item="moduleItem" ></tab-history>

                    </b-tab>




                </b-tabs>
            </b-card-body>
        </b-card>

    </div>
</template>

<script>


    import {
         BCardText, BCard, BCardHeader, BCardBody,
        BTabs, BTab, BAlert, BButton,

    } from 'bootstrap-vue'

    import tabDetails from './tabs/tabDetails'

    import tabNotes from './tabs/tabNotes'

    import tabDocuments from './tabs/tabDocuments'

    import tabPayments from './tabs/tabPayments'

    import tabMlm from './tabs/tabMlm'

    import tabHistory from './tabs/tabHistory'


    import {MODULE_PREFIX, NOTE_PREFIX, PAYMENT_PREFIX} from './moduleHelper'

    export default {

        components: {

            BCardText,
            BCard, BCardHeader, BCardBody,
            BTabs, BTab, BAlert, BButton,

            tabDetails,

            tabNotes,

            tabDocuments,
            tabPayments,
            tabMlm,
            tabHistory

        },
        data() {
            return {
                PAYMENT_PREFIX,
                NOTE_PREFIX,
                MODULE_PREFIX,


                editedTask:false,
                editedNote:false,
                curPageLabel: 'label_informations',
                moduleItem: false,

            }
        },

        created() {
            this.fetchModuleItem();
        },
        mounted(){

            let alias_route_param = this.$router.currentRoute.params.alias;
            let self = this;
            if(alias_route_param){
                this.$nextTick(function(){self.$refs[alias_route_param+'_TAB'].activate();});
            }

        },

        methods: {

            fetchModuleItem(){
                let id = this.$router.currentRoute.params.id;
                this.async('get', '/'+this.MODULE_PREFIX+'/' + id, {}, function (resp) {

                    this.moduleItem = resp.data;
                });

            },
            resolveActiveTab(){
                return false;
            }

        },


    }
</script>

