<template>
    <div>
        <b-row>
            <b-col
                    cols="12"
                    md="6"
                    class=" mb-1"
            >

                <b-table-simple class="table-details" responsive no-border-collapse>
                    <b-tbody>
                        <b-tr>
                            <b-td width="40%">{{$t('label_phone')}}</b-td>
                            <b-td class="actions">

                                {{editedItem.phone}}

                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-td width="40%">{{$t('label_username')}}</b-td>
                            <b-td class="actions">

                                {{editedItem.name}}

                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-td width="40%">{{$t('label_email_address')}}</b-td>
                            <b-td class="actions">

                                {{editedItem.emailAddress}}

                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-td width="40%">{{$t('label_department')}}</b-td>
                            <b-td class="actions">

                                {{editedItem.department? editedItem.department.name : ''}}

                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-td width="40%">{{$t('label_position')}}</b-td>
                            <b-td class="actions">
                                {{editedItem.position}}
                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-td width="40%">{{$t('label_manager')}}</b-td>
                            <b-td class="actions">

                                {{editedItem.manager? editedItem.manager.name : ''}}

                            </b-td>
                        </b-tr>
                        <b-tr v-if="['admin', 'superadmin'].includes(userRole(authUser()))">
                            <b-td width="40%">{{$t('label_keeper')}}</b-td>
                            <b-td class="actions">

                                {{editedItem.agent_data && editedItem.agent_data.consultant? editedItem.agent_data.consultant.name : ''}}

                            </b-td>
                        </b-tr>

                        <b-tr>
                            <b-td width="40%">{{$t('label_country')}}</b-td>
                            <b-td class="actions">

                                {{editedItem.agent_data && editedItem.agent_data.country? editedItem.agent_data.country.countryName : ''}}

                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-td width="40%">{{$t('label_city')}}</b-td>
                            <b-td class="actions">

                                {{editedItem.agent_data? editedItem.agent_data.town : ''}}

                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-td width="40%">{{$t('label_street_and_house_number')}}</b-td>
                            <b-td class="actions">

                                {{editedItem.agent_data? editedItem.agent_data.street : ''}}

                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-td width="40%">{{$t('label_post_code')}}</b-td>
                            <b-td class="actions">

                                {{editedItem.agent_data?editedItem.agent_data.zip : ''}}

                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-td width="40%">{{$t('label_company')}}</b-td>
                            <b-td class="actions">

                                {{$t(editedItem.agent_data && editedItem.agent_data.company == 'Y'?'label_yes' : 'label_no')}}

                            </b-td>
                        </b-tr>
                    </b-tbody>
                </b-table-simple>
            </b-col>
            <b-col
                    cols="12"
                    md="6"
                    class=" mb-1"
            >
                <b-table-simple class="table-details" responsive  no-border-collapse>
                    <b-tbody>

                        <b-tr>
                            <b-td width="40%">{{$t('label_name_of_company')}}</b-td>
                            <b-td class="actions">

                                {{editedItem.agent_data? editedItem.agent_data.company_name : ''}}

                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-td width="40%">{{$t('label_nip')}}</b-td>
                            <b-td class="actions">

                                {{editedItem.agent_data? editedItem.agent_data.NIP : ''}}

                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-td width="40%">{{$t('label_krs')}}</b-td>
                            <b-td class="actions">

                                {{editedItem.agent_data? editedItem.agent_data.KRS : ''}}

                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-td width="40%">{{$t('label_account_number')}}</b-td>
                            <b-td>
                                {{editedItem.agent_data?editedItem.agent_data.account_number : ''}}
                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-td width="40%">{{$t('label_bank_name')}}</b-td>
                            <b-td>
                                {{editedItem.agent_data?editedItem.agent_data.bank_name : ''}}
                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-td width="40%">{{$t('label_vat_payer')}}</b-td>
                            <b-td class="actions">
                                {{$t(editedItem.agent_data && editedItem.agent_data.vat_payer == 'Y'?'label_yes' : 'label_no')}}
                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-td width="40%">{{$t('label_vat_payer_description')}}</b-td>
                            <b-td>
                                {{editedItem.agent_data?editedItem.agent_data.vat_payer_txt : ''}}
                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-td width="40%">{{$t('label_date_of_signing_agreement')}}</b-td>
                            <b-td>
                                {{editedItem.agent_data && editedItem.agent_data.agreement_date? formatDate(editedItem.agent_data.agreement_date, 'DD.MM.YYYY', 'unix') : ''}}
                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-td width="40%">{{$t('label_id_of_partner')}}</b-td>
                            <b-td>
                                {{editedItem.agent_data?editedItem.agent_data.id_of_partner : ''}}
                            </b-td>
                        </b-tr>



                    </b-tbody>
                </b-table-simple>



            </b-col>
        </b-row>

        <b-row clss="mt-3">
            <b-col cols="12">

                <b-table-simple caption-top bordered small responsive no-border-collapse>
                    <caption> <b>{{$t('label_provision_general')}}</b>
                        <b-button size="sm" pill v-if="$can('edit', 'agent.tab_details')" type="button"
                                                                                   variant="outline-success"
                                                                                   class=" ml-1"
                                                                                   @click="provisionTitle = 'label_provision_general';provisionType = 'general_provision';$bvModal.show(PROVISION_PREFIX+'-modal')">{{$t('label_add_new')}}</b-button>
                    </caption>
                    <b-thead head-variant="info">
                        <b-tr>
                            <b-th>{{$t('label_start_date')}}</b-th>
                            <b-th>{{$t('label_end_date')}}</b-th>
                            <b-th>{{$t('label_parcentege')}}</b-th>
                        </b-tr>
                    </b-thead>
                    <b-tbody>
                        <template v-if="editedItem.agent_data && editedItem.agent_data.provision_general.length > 0">
                            <b-tr v-for="provision in editedItem.agent_data.provision_general">
                                <b-td>{{provision.provision_start? formatDate(provision.provision_start, 'DD.MM.YYYY', 'unix') : ''}}</b-td>
                                <b-td>{{provision.provision_end? formatDate(provision.provision_end, 'DD.MM.YYYY', 'unix') : ''}}</b-td>
                                <b-td>{{provision.provision_percent}}</b-td>
                            </b-tr>
                        </template>
                    </b-tbody>
                </b-table-simple>
            </b-col>
        </b-row>

        <br>
        <provision-modal :provision-type="provisionType" :provision-title="provisionTitle" @item-added="$emit('provision-added')" :edited-item="editedProvision" ></provision-modal>
    </div>
</template>

<script>
    import {
        BPopover,
        BTableSimple, BTbody, BTr, BTd, BButton,BThead, BTh

    } from 'bootstrap-vue'

    import provisionModal from './../modals/processProvisionModal'
    import { MODULE_PREFIX, PROVISION_PREFIX} from './../moduleHelper'
    export default {
        components: {
            BPopover,
            BTableSimple, BTbody, BTr, BTd, BButton,BThead, BTh,
            provisionModal
        },

        props: ['editedItem'],
        data() {
            return {
                PROVISION_PREFIX,
                MODULE_PREFIX,

                editedProvision: '',
                provisionTitle: '',
                provisionType: ''

            }
        },

    }
</script>