<template>
    <!--static-->
    <b-modal
            :id="PREFIX + '-modal'"
            hide-footer
            @hide="resetForm()"
            @shown="$emit('modalShown');initData()"


    >
        <template #modal-title>
            {{$t(provisionTitle)}}
        </template>

        <template #default="{ hide }">

        <validation-observer
                tag="form"
                class="p-1"
                #default="{handleSubmit, reset, invalid}"
                :ref="PREFIX + '_FORM'"

        >

                <validation-provider
                        #default="validationProps"
                        :name="$t('label_parcentege')"
                        rules="required|numeric"
                        slim
                >
                    <b-form-group
                            :label="$t('label_parcentege')"
                    >
                        <b-form-input
                                required
                                v-model="itemData.provision_percent"
                                :state="getValidationState(validationProps)"
                                :placeholder="$t('label_parcentege')"
                        />

                        <b-form-invalid-feedback :state="getValidationState(validationProps)">
                            {{ validationProps.errors[0] }}
                        </b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>

            <validation-provider

                    #default="validationProps"
                    :name="$t('label_start_date')"
                    rules="required"
                    class="mr-1 flex-grow-1"
                    slim
            >
                <b-form-group :label="$t('label_start_date')">

                    <custom-date-picker
                            :class="{'date--picker': true , 'is-invalid':getValidationState(validationProps) === false, 'is-valid':getValidationState(validationProps) === true}"
                            :locale="$store.state.appConfig.locale"
                            :placeholder="$t('label_DD-MM-YYYY')"
                            :value="itemData.provision_start"

                            @input="itemData.provision_start = $event"
                    >
                        <template #clear-btn="{ vm }"><feather-icon icon="XIcon" size="14"/></template>
                        <div slot="label">
                            <feather-icon title="Clear" data-toggle icon="CalendarIcon" size="18"/>
                        </div>
                    </custom-date-picker>


                    <b-form-invalid-feedback :state="getValidationState(validationProps)">
                        {{ validationProps.errors[0] }}
                    </b-form-invalid-feedback>
                </b-form-group>
            </validation-provider>

            <validation-provider

                    #default="validationProps"
                    :name="$t('label_end_date')"
                    rules="required"
                    class="mr-1 flex-grow-1"
                    slim
            >
                <b-form-group :label="$t('label_end_date')">

                    <custom-date-picker
                            :class="{'date--picker': true , 'is-invalid':getValidationState(validationProps) === false, 'is-valid':getValidationState(validationProps) === true}"
                            :locale="$store.state.appConfig.locale"
                            :placeholder="$t('label_DD-MM-YYYY')"
                            :value="itemData.provision_end"

                            @input="itemData.provision_end = $event"
                    >
                        <template #clear-btn="{ vm }"><feather-icon icon="XIcon" size="14"/></template>
                        <div slot="label">
                            <feather-icon title="Clear" data-toggle icon="CalendarIcon" size="18"/>
                        </div>
                    </custom-date-picker>

                    <b-form-invalid-feedback :state="getValidationState(validationProps)">
                        {{ validationProps.errors[0] }}
                    </b-form-invalid-feedback>
                </b-form-group>
            </validation-provider>

            <div class="d-flex justify-content-end mt-2">
                <button @click.prevent="$bvModal.hide(PREFIX + '-modal')" type="reset" class=" btn btn-default">{{$t('label_close')}}</button>

                <button @click.prevent="handleSubmit(onSubmit);focusOnFormError(invalid)" type="submit"
                        class="btn btn-primary waves-effect waves-light">{{(action == 'editing')?$t('label_save'):$t('label_add')}}</button>
            </div>

        </validation-observer>

        </template>

    </b-modal>
</template>


<script>
    import { ValidationProvider, ValidationObserver} from 'vee-validate'

    import {
         BFormTextarea,
         BFormGroup, BFormInput, BFormInvalidFeedback, BInputGroupAppend, BInputGroup, BFormCheckbox, BInputGroupPrepend,
    } from 'bootstrap-vue'
    import  fileInput from '@/views/components/fileUploadInput'
    import infinityScroll from '@/views/components/infinityScroll'
    import vSelect from 'vue-select'
    import {serialize} from 'object-to-formdata';
    import { PROVISION_PREFIX as PREFIX, MODULE_PREFIX, PAYMENT_PREFIX} from './../moduleHelper'

    export default {
        components: {

             BFormGroup, BFormInput, BFormInvalidFeedback, BFormTextarea, BInputGroup, BInputGroupAppend, BFormCheckbox, BInputGroupPrepend,

            ValidationProvider,
            ValidationObserver,
            infinityScroll,
            fileInput,
            vSelect
        },
        props:['moduleItem','editedItem', 'provisionType', 'provisionTitle'],
        data() {
            return {

                MODULE_PREFIX,
                PAYMENT_PREFIX,
                PREFIX,

                action: 'adding',

                blankItemData: {
                    type: '',
                    provision_start:'',
                    provision_end:'',
                    provision_percent:'',

                },

                itemData: {},

            }
        },

        methods: {
            initData(){



                if(this.editedItem){

                } else {
                    this.action = 'adding';

                    this.itemData =  Object.assign({},this.blankItemData);

                    switch(this.provisionType){
                        case 'general_provision': this.itemData.type = 1; break;
                        case 'court_provision': this.itemData.type = 2;break;
                        case 'central_provision': this.itemData.type = 3;break;
                        case 'upper_provision': this.itemData.type = 4;break;
                    }


                }
            },
            resetForm() {
                this.itemData = Object.assign({},this.blankItemData);

                this.$refs[this.PREFIX + '_FORM'].reset();
            },
            onSubmit(){

                this.itemData.user_id = this.$router.currentRoute.params.id;
                if (this.action == 'adding') {
                    this.async('post', '/' + this.MODULE_PREFIX  + '/addNewProvision', this.itemData, function (resp) {
                        this.$emit('item-added', resp.data.item);
                        this.$bvModal.hide(this.PREFIX + '-modal');
                    });``

                } else {

                }

            }
        },


    }
</script>