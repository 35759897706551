<template>

    <div>
        <div>
            <b-alert class="mx-2 mt-2" variant="info" show>
                <div class="">
                    1.W tym miejscu wygenerujesz umowę zlecenie i rachunek za wypracowane prowizje, zaliczki i premie. <br>

                    2.Najpierw pobierz, wypełnij i podpisz oświadczenie ZUS (dla celów ubezpieczeniowych i podatkowych).<br>

                    3.Oświadczenie zeskanuj i dodaj do rozliczenia prowizji.<br>

                    4.Za 3 dni otrzymasz do Systemu rachunek. Wydrukuj w 2 egz., wypełnij i podpisz.<br>

                    5.Wyślij pocztą do Kancelarii po 2 egz. Podpisanych oświadczeń ZUS i rachunków.<br>

                    6.Przelew na wskazany numer konta otrzymasz w ciągu 3 dni od wpływu dokumentów do Kancelarii.
                </div>
            </b-alert>
        </div>

        <provision-table module="module_agent"  :agent-id="moduleItem.id"></provision-table>

    </div>
</template>

<script>
    import {

        BTable, BDropdownItem, BDropdown, BPagination, BMedia,
        BMediaAside,
        BMediaBody,
        BAvatar,
        BButton, BAlert

    } from 'bootstrap-vue'
    import provisionTable from '../../agent/includes/provisionTable'
    import vSelect from 'vue-select'

    import {NOTE_PREFIX as PREFIX, MODULE_PREFIX} from './../moduleHelper'

    export default {
        components: {

            BTable, BDropdownItem, BDropdown, BPagination, BMedia,
            BMediaAside,
            BMediaBody,
            BAvatar, BAlert,
            vSelect,

            BButton,
            provisionTable

        },
        props: ['moduleItem'],
        data() {
            return {
                MODULE_PREFIX,
                PREFIX,

            }
        },

        methods: {

        },

    }
</script>
<style lang="scss">
    .line-actions {
        position: absolute;
        right: 0;
        top: 0;
    }

    .email-media-list {
        width: 100%;
    }


</style>